import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAF_oC2gQ3_qJoBY_juk_MHN2wX5PKltN0",
  authDomain: "sparkmemecom.firebaseapp.com",
  projectId: "sparkmemecom",
  storageBucket: "sparkmemecom.appspot.com",
  messagingSenderId: "88645800821",
  appId: "1:88645800821:web:7d33f81f60208a5b51393c",
  measurementId: "G-4NJ5J62PTW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const storage = getStorage(app);