import React from "react";
import { useState, useEffect } from "react";
import { ref, getDownloadURL, listAll,} from "firebase/storage";
import { storage } from "./firebase";

function Gallery() {
    const [imageUrls, setImageUrls] = useState([]);
  
    const imagesListRef = ref(storage, "images/");
  
    useEffect(() => {
      listAll(imagesListRef).then((response) => {
        response.items.forEach((item) => {
          getDownloadURL(item).then((url) => {
            setImageUrls((prev) => [...prev, url]);
          });
        });
      });
    }, []);
  
    return (
      <div className="App">
        {imageUrls.map((url) => {
          return <a href={url}><img src={url} /></a>;
        })}
      </div>
    );
  }
  
  export default Gallery;