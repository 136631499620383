import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Gallery from "./Gallery";
import styled, { css } from "styled-components";


function App(props) {
  return (
    <Container>
      <SparkmemeCom>Sparkmeme.com</SparkmemeCom>
      <Subtitle>
        Startups, Entrepreneurs, Makers, Engineers, we talk shit about anyone! 😈
      </Subtitle>
      <Sparkmate>Brought to you with Sarcasm by the <a href="https://www.sparkmate.co" target="_blank">Sparkmate team</a></Sparkmate>
      <Gallery />
      <Footer>Sparkmate LTD. All rights reserved</Footer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  background-color: rgba(26,26,26,1);
  flex-direction: column;
  align-items: center;
  width: 100vw;
`;

const SparkmemeCom = styled.span`
  text-transform: uppercase;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 700;
  color: rgba(255,255,255,1);
  font-size: 35px;
  text-align: center;
  margin-top: 50px;
  width: 300px;
  height: 50px;
`;

const Subtitle = styled.span`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  color: rgba(255,255,255,1);
  width: 300px;
  text-align: center;
  height: 40px;
  align-self: center;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const Sparkmate = styled.span`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 700;
  color: rgba(255,255,255,1);
  width: 298px;
  text-align: center;
  margin-top: 20px;
`;

const Footer = styled.span`
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  color: rgba(255,255,255,1);
  text-align: center;
  width: 300px;
  margin: 20px;
`;


export default App;
